@use '@/styles/utils/mixins.scss' as *;

.historyWrapper {
  display: flex;
  position: relative;

  .historyListLine {
    width: 0.125rem;
    height: 100%;
    background-color: $primary;
    position: absolute;
    left: 0.625rem;
    top: 0;
  }

  .historyList {
    display: flex;
    flex-direction: column;
    gap: 5rem;
    padding-left: 0.625rem;

    overflow-y: auto;
    overflow-x: visible;
    height: 100%;
    width: 100%;
    max-height: calc(100vh - 7.5rem);
    position: relative;
  }
  .historyDescription {
    position: relative;
    padding-bottom: 0.625rem;
    .yellowDot {
      position: absolute;
      left: -2.5rem;
      top: 0;
      width: 1.25rem;
      height: 1.25rem;
      border-radius: 50%;
      background-color: $primaryAlt;
      transition: all 0.3s ease-in-out;
      opacity: 0;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-bottom: 1.5rem;
      font-weight: 500;
      &:not(:first-child) {
        margin-top: 2.5rem;
      }
    }
    h1 {
      font-size: 6rem;
    }
    h2 {
      font-size: 4.5rem;
    }
    h3 {
      font-size: 3rem;
    }
    h4 {
      font-size: 2.125rem;
    }
    h5 {
      font-size: 1.5rem;
      @include mob() {
        font-size: 1rem;
      }
    }
    h6 {
      font-size: 1.5rem;
      @include mob() {
        font-size: 1rem;
      }
    }

    p {
      margin-bottom: 1rem;
    }
    ul,
    ol {
      padding-left: 1.5rem;
      margin-bottom: 1rem;
    }
  }
  .historyItem {
    display: flex;
    flex-direction: column;
    gap: 0.875rem;
    max-width: 22.75rem;
    padding-left: 1.875rem;
    cursor: default;
    &.active {
      .yellowDot {
        opacity: 1;
      }
    }
  }
}
